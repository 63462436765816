import React,{useEffect,useMemo,useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { fetchMerchantToken } from './Redux/MerchantToken/MerchantTokenActions';
import { fetchConfig } from './Redux/Config/ConfigActions';
//import './App.css';
import Header from './components/Header'
import BelowHeader from './components/BelowHeader'
import BannerNew from './components/BannerNew'
import Menu3 from './components/Menu3'
import BookTable from './components/BookTable'
import Footer from './components/Footer'
import PasswordGate from "react-password-gate";

function App() {
  // store data access start
const merchant_data = useSelector(state =>state.MerchantToken)
const config_data = useSelector(state =>state.Config)

const accessList = [
  "diningtek:$2a$10$pxN0ayelBesFjdUCPhT/1uZ9D8LEOIx4hU1j6rjch83sL2DmIu1lK",
  "diningtekdemo:$2a$10$0.ttB4YNicriz6ptb7SGB.SnJamiYO6kux7Wnguaq3B5nzGs./BsW"
];

// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [merchantInfo,setMerchantInfo] = useState([])
  const [configInfo,setConfigInfo] = useState([])
  const [detailed_cart_item,setDetailed_cart_item] = useState([])
  const [banner_info,setBanner_info] = useState([])
  // component all states define End

   //hooks start
   // useEffect(() =>{
   //   dispatch(fetchMerchantToken())
   // },[dispatch])

   useMemo(()=>{
      setMerchantInfo(merchant_data.merchant_token.object)
  },[merchant_data && merchant_data.merchant_token && merchant_data.merchant_token.object])

  // useEffect(() =>{
  //   if(merchantInfo && merchantInfo.access_token){
  //     const user_token = merchantInfo.access_token
  //     dispatch(fetchConfig(user_token))
  //   }
  //
  // },[merchantInfo && dispatch])

  useMemo(()=>{
   if(config_data && config_data.config && config_data.config.object){
     setConfigInfo(config_data.config.object)
   }
 },[config_data])
   //hooks end

const detailed_cart_item_callbackFunction = (childdata) =>{
  setDetailed_cart_item(childdata)
}
const banner_info_callbackFunction = (childdata) =>{
  setBanner_info(childdata)
}
  return (
    <div className="App">
      <Header configInfo={configInfo} Detailed_cart_item={detailed_cart_item}/>
      {
      //  <BelowHeader />
      }
      <BannerNew />

      {merchantInfo ?

      <Menu3 configInfo={configInfo}
      merchantInfo={merchantInfo}
      detailed_cart_item_parentcallbackfunction = {detailed_cart_item_callbackFunction}
      banner_info_parentcallbackfunction = {banner_info_callbackFunction}
      />
     :(<div className="row">
     <div className="col-12">
       <div className="menuLoader hm front">
         <img src="/img/menu-item-loader.gif"/>
         <img src="/img/menu-item-loader.gif"/>
           <img src="/img/menu-item-loader.gif"/>
             <img src="/img/menu-item-loader.gif"/>
       </div>
       </div>
      </div>
     )}
      {
        // <BookTable />
      }

      <Footer configInfo={configInfo} merchantInfo={merchantInfo} banner_info={banner_info}/>
    </div>
  );
}

export default App;
