export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/world-cafe-banner-3.png',
    banner_img_root : '/img/world-cafe-banner-2.png',
    resid : 'b4f719deaa3476c0b638a14ed84a543e',
    key_value : 'lindasworldcafe',
    secret_value : 'lindasworldcafe',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.410435109968!2d-105.2928634851192!3d39.999278788846595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bec6ab3a9d6d9%3A0x60c86f3fa0acaa77!2s770%20Baseline%20Rd%20%23200%2C%20Boulder%2C%20CO%2080302%2C%20USA!5e0!3m2!1sen!2sin!4v1580301964420!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"World Cafe"
};
